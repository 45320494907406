<template>
  <div>
    <h3>使用频次页面</h3>
  </div>
</template>

<script>
export default {
  name: 'UseFrequency',
  data () {
    return {
      result: '占位'
    }
  }
}
</script>

<style>

</style>
